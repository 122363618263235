<script lang="ts" setup>
import { PropType } from 'vue'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TextSizes } from '~~/src/constants/textSizes'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { ModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'
import { Justifier, Price } from '~~/src/submodules/sharedTypes/common/Price'
import { utilJustifiers } from '~~/src/utils/utilJustifiers'
import { utilModifiers } from '~~/src/utils/utilModifiers'
import { utilNumber } from '~~/src/utils/UtilNumber'

const props = defineProps({
	price: { type: Object as PropType<Price>, required: true },
	currency: { type: Object as PropType<Currency>, required: true },
	isHardLink: { type: Boolean, default: false },
})
const { price } = toRefs(props)
const justifiers = computed(() => (price.value.justifiers || ([] as Justifier[])).filter((el) => el.value != 0))
const modifiers = computed(() => utilModifiers.activeModifiers(price.value))
const basePrice = computed(() => price?.value?.startingPrice)
const title = computed(() =>
	price.value.isRoomTypeIndexed
		? (useLocale().translate(TranslationKeys.PRICE_DERIVED_FROM, [''], false) as TranslationKey)
		: TranslationKeys.BASE_PRICE
)

const getModifierIcon = (modifier: ModifierType) => utilModifiers.modifierIcon(modifier)
const getModifierText = (modifier: ModifierType) => utilModifiers.modifierText(modifier)
</script>

<template>
	<div class="justifier-tooltip flex flex-row rounded-md bg-main-blue-900">
		<div v-if="price.isRoomTypeIndexed" class="pl-2 pt-3.5">
			<CommonIcon
				:icon-name="isHardLink ? SpSvg.CalendarModLink : SpSvg.CalendarModSemiLink"
				:icon-size="IconSizes.XS"
				fill="fill-white"
			/>
		</div>
		<div class="grid gap-2 p-2">
			<!-- title -->
			<div class="grid grid-cols-[minmax(10rem,15rem)_5rem] items-center gap-1">
				<div class="flex items-center">
					<CommonText :text="title" :text-size="TextSizes.PRODUCTIVE" class="font-semibold text-white" />
				</div>
				<span v-if="!price.isRoomTypeIndexed" class="grid text-right text-xs font-semibold text-white">
					{{ utilNumber.toCurrency(basePrice, currency) }}
				</span>
			</div>

			<!-- justifiers rows -->
			<div v-if="price.isRoomTypeIndexed" class="grid grid-cols-[1fr_auto] gap-1">
				<span class="text-xs text-main-blue-200">
					{{ price.indexedPrice?.linkedRoomType.name }}
				</span>
				<span class="text-right text-xs text-main-blue-200">
					{{ utilNumber.toCurrency(price.indexedPrice?.linkedRoomTypePredictedPrice || 0, currency) }}
				</span>

				<span> </span>
				<span class="text-right text-xs text-main-blue-200">
					{{ utilJustifiers.readableLinkedRoomModifier(price.indexedPrice?.linkedRoomType!, currency) }}
				</span>
			</div>

			<div v-else-if="justifiers.length > 0" class="grid gap-1">
				<div v-for="justifier in justifiers" class="grid grid-cols-[minmax(10rem,15rem)_5rem] gap-1">
					<span class="text-xs" :class="utilJustifiers.justifierColor(justifier)">
						{{ utilJustifiers.translateJustifier(justifier) }}
					</span>
					<span class="text-right text-xs" :class="utilJustifiers.justifierColor(justifier)">
						{{ utilJustifiers.readableJustifierValue(justifier, currency) }}</span
					>
				</div>
			</div>

			<!-- modifiers rows -->
			<div v-if="modifiers.length > 0" class="grid gap-1">
				<template v-for="modifier in modifiers">
					<div class="grid grid-cols-[minmax(10rem,15rem)_5rem] gap-1">
						<div class="flex items-center gap-1">
							<CommonIcon fill="fill-yellow-300" :icon-name="getModifierIcon(modifier)" :icon-size="IconSizes.XS" />
							<CommonText class="text-yellow-300" :text-size="TextSizes.PRODUCTIVE" :text="getModifierText(modifier)" />
						</div>
						<span class="text-right text-xs text-yellow-300">
							{{ utilModifiers.modifierToString(modifier, price?.modifiers[modifier]!, currency) }}
						</span>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.justifier-tooltip {
	box-shadow:
		0px 9px 24px 0px rgba(0, 0, 0, 0.2),
		0px 3px 6px 0px rgba(0, 0, 0, 0.1),
		0px 0px 0px 1px rgba(0, 0, 0, 0.05);
}
</style>
